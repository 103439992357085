import "./home.css";
import React, {Fragment, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuoteLeft, faQuoteRight} from "@fortawesome/free-solid-svg-icons";
import {daysBetween, formatMonthDay} from "../../util/helpers";

export const Home = () => {

    const [callouts, setCallouts] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventImage, setEventImage] = useState([]);
    const [email, setEmail] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
            setShowModal(true);

            fetch("https://strapi.waywardcookies.com/api/home-callouts?populate=*")
                .then(response => response.json())
                .then(resp => setCallouts(resp.data))
                .catch(error => console.error("Error fetching data:", error));
            fetch("https://strapi.waywardcookies.com/api/testimonials?populate=*")
                .then(response => response.json())
                .then(resp => setTestimonials(resp.data))
                .catch(error => console.error("Error fetching data:", error));
            fetch("https://strapi.waywardcookies.com/api/events?populate=*")
                .then(response => response.json())
                .then(resp => setEvents(resp.data))
                .catch(error => console.error("Error fetching data:", error));
            fetch("https://strapi.waywardcookies.com/api/event-callout-image?populate=*")
                .then(response => response.json())
                .then(resp => setEventImage(resp.data.CalloutImage))
                .catch(error => console.error("Error fetching data:", error));
            document.title = "Wayward Cookies : Awesome Custom Cookies";
        },
        []);

    const formatDates = (event) => {
        const startDate = new Date(event.StartDate + "T00:00:00");
        let endDate = event.EndDate;
        if (endDate) {
            endDate = new Date(endDate + "T00:00:00");
        }

        if (!endDate) {
            return formatMonthDay(startDate);
        }
        if (startDate.getMonth() !== endDate.getMonth()) {
            return formatMonthDay(startDate) + " - " + formatMonthDay(endDate);
        }
        if (daysBetween(startDate, endDate) === 1) {
            return formatMonthDay(startDate) + " & " + endDate.getDate();
        }
        return formatMonthDay(startDate) + " - " + endDate.getDate();
    }

    const subscribe = () => {
        if (email && email.trim()) {
            fetch(process.env.REACT_APP_WAYWARD_BACKEND_URL + "/marketing/subscribe", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: email
                })
            }).then(response => {
                if (response.status === 200) {
                    alert("Thank you for subscribing!");
                    setEmail("");
                    setShowModal(false);
                } else {
                    alert("There was an error subscribing. Please try again later.");
                }
            }).catch(error => {
                console.error("Error subscribing:", error);
                alert("There was an error subscribing. Please try again later.");
            });
        }
    }

    return (
        <Fragment>
            {showModal && <div className="darkBackdrop" onClick={() => setShowModal(false)}></div>}
            <div className={"container-fluid"}>
                <div className="overflow-hidden position-relative mt-3">
                    <div className="container-fluid main-hero" style={{height: "30vh"}}></div>
                </div>
            </div>
            <div className={"container mt-5 mb-2"}>
                {
                    callouts?.sort((a, b) => {
                        return a.Order - b.Order;
                    }).map((callout) => (
                        <div className={"row pb-5"} key={callout.CalloutTitle.replace(" ", "")}>
                            <div
                                className={"col-lg-5 d-flex align-content-center py-3 px-5 order-lg-2 " + (callout.Order % 2 ? "order-lg-2" : "order-lg-1")}>
                                <img
                                    src={`https://strapi.waywardcookies.com${callout.CalloutImage.formats.small.url}`}
                                    alt={callout.CalloutImage.alternativeText}
                                    style={{margin: "auto", border: "#655A4F solid 1px"}} width={"100%"}/>
                            </div>
                            <div
                                className={"col-md d-flex align-items-center px-5 " + (callout.Order % 2 ? "order-lg-1" : "order-lg-2")}>
                                <div>
                                    <h3 className={"article-title pb-2"}>{callout.CalloutTitle}</h3>
                                    <p className={"pb-3"}>
                                        {callout.CalloutText}
                                    </p>
                                    <p>
                                        <NavLink to={callout.ButtonURL}
                                                 className={"btn btn-primary"}>{callout.ButtonText}</NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className={"row pb-5"}>
                    <div className={"col-lg-5 d-flex order-lg-2 align-content-center py-3 px-5"}>
                        {/*<img src={process.env.PUBLIC_URL + "/events-medium.jpg"} alt={"cats"}*/}
                        {/*     style={{margin: "auto", border: "#655A4F solid 1px"}} width={"100%"}/>*/}
                        <img
                            src={`https://strapi.waywardcookies.com${eventImage?.formats?.small?.url}`}
                            alt={"events"} style={{margin: "auto", border: "#655A4F solid 1px"}} width={"100%"}/>
                    </div>
                    <div className={"col-md d-flex align-items-center order-lg-1 px-5"}>
                        <div>
                            <h3 className={"article-title pb-3"}>Upcoming Events</h3>
                            <ul className={"pb-2"}>
                                {
                                    events
                                        .sort((a, b) => {
                                            const dateA = new Date(a.StartDate);
                                            const dateB = new Date(b.StartDate);
                                            return dateA - dateB;
                                        })
                                        .map(event => {
                                            if (event.EventURL) {
                                                return <li key={event.id}>
                                                    <a href={event.EventURL}
                                                       target={"_blank"}>{event.EventTitle}
                                                    </a>&nbsp;|&nbsp;{formatDates(event)}
                                                </li>
                                            } else {
                                                return <li
                                                    key={event.id}>{event.EventTitle}&nbsp;|&nbsp;{formatDates(event)}</li>
                                            }
                                        })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"row pb-3"}>
                    {
                        testimonials?.sort((a, b) => {
                            return a.Order - b.Order;
                        }).map((testimonial) => (
                            <div className={"col-lg-4 d-flex align-content-center mb-5 px-4"}
                                 key={testimonial.ReviewerName.replace(" ", "")}>
                                <div>
                                    <p className={"text-center flex-grow-1 review-text"}>
                                        <FontAwesomeIcon icon={faQuoteLeft} className={"pe-1"}/>
                                        {testimonial.ReviewText[0].children[0].text}
                                        <FontAwesomeIcon icon={faQuoteRight} className={"ps-1"}/>
                                    </p>
                                    <h3 className={"reviewer-name text-end pe-4"}>- {testimonial.ReviewerName}</h3>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={"row pb-3"}>
                <div className={"col text-center h5"}>
                    <b>Don't miss out on upcoming presales, events, and cookie classes!</b>
                </div>
            </div>
            <div className={"row pb-3 justify-content-center"}>
                <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-5 d-flex"}>
                    <div className="input-group">
                        <input id="email" type="email" className="form-control subscribe-input" value={email}
                               aria-placeholder={"Enter your email"}
                               onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your Email"/>

                        <button className="btn btn-primary subscribe-button" type={"button"}
                                onClick={subscribe}>Subscribe
                        </button>
                    </div>
                </div>
            </div>
            <div className="modal modal-lg" id="newsletterModal" tabIndex="-1" aria-labelledby="newsletterModalLabel"
                 style={{display: showModal ? 'block' : 'none'}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-5">
                        <div className="modal-body">
                            <button type="button" className="btn-close close-modal" data-bs-dismiss="modal"
                                    aria-label="Close" onClick={() => setShowModal(false)}></button>
                            <div className={"row pb-4"}>
                                <div className={"col"}>
                                    <img src={process.env.PUBLIC_URL + "/macaron-square-crop.png"}
                                         alt={"Subscribe to our newsletter"}
                                         style={{width: "100%"}}/>
                                </div>
                            </div>
                            <div className={"row pb-2"}>
                                <div className="col text-center">
                                    <h5>
                                        Join our mailing list.
                                    </h5>
                                </div>
                            </div>
                            <div className={"row pb-4"}>
                                <div className="col text-center">
                                    <h5>
                                        Be the first to know about upcoming presales, events, & classes!
                                    </h5>
                                </div>
                            </div>
                            <div className={"row pb-5"}>
                                <div className={"col"}>
                                    <div className={"input-group input-group-lg"}>
                                        <input id="email" type="email" className="form-control subscribe-input"
                                               value={email}
                                               aria-placeholder={"Enter your email"}
                                               onChange={(e) => setEmail(e.target.value)}
                                               placeholder="Enter Your Email"/>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col text-center"}>
                                    <button className="btn btn-primary subscribe-button" type={"button"}
                                            onClick={subscribe}>Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
