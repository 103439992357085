import {NavLink, useLocation} from "react-router-dom";
import "./header.css";
import {Fragment, useEffect, useState} from "react";
import ReactGA from "react-ga4";
import {BlocksRenderer} from '@strapi/blocks-react-renderer';

export const Header = () => {

    let location = useLocation();
    const [bannerContent, setBannerContent] = useState("");

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_ID);

        fetch("https://strapi.waywardcookies.com/api/top-banner-content?populate=*")
            .then(response => response.json())
            .then(resp => setBannerContent(resp.data))
            .catch(error => console.error("Error fetching data:", error));
    }, []);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
    }, [location.pathname]);

    const handleActiveStyle = ({isActive, isPending}) => {
        const baseClass = "nav-link px-5 px-md-3 ";
        return isPending ? baseClass + "pending" : isActive ? baseClass + "active" : baseClass
    };
    return (
        <Fragment>
            <div className="container-fluid text-center p-2 banner-content"
                 style={{backgroundColor: "#FF9BC0", color: "#463e37"}}>
                {
                    bannerContent?.Content &&
                    <BlocksRenderer content={bannerContent.Content}/>
                }
            </div>
            <nav className="navbar navbar-expand-md" id={"header"}>
                <div className="container-fluid">
                    <a className="navbar-brand d-block d-md-none flex-fill" href="/">
                        <img src={process.env.PUBLIC_URL + "/wayward-logo-text-large.png"} alt={"Wayward Cookies Logo"}
                             width={"100%"}/>
                    </a>
                    <button className="navbar-toggler me-4" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                        <div className="navbar-nav mb-2 mt-2 mb-lg-0 align-items-md-center">
                            <ul className="navbar-nav nav-block">
                                <li className="nav-item">
                                    <NavLink to="/" className={handleActiveStyle}>
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/gallery" className={handleActiveStyle}>
                                        Gallery
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/custom-order" className={handleActiveStyle}>
                                        Custom Order
                                    </NavLink>
                                </li>
                            </ul>
                            <a className="navbar-brand d-none d-md-block" href="/">
                                <img src={process.env.PUBLIC_URL + "/wayward-logo-circle-text.png"}
                                     alt={"Wayward Cookies Logo"}
                                     width={"100%"}/>
                            </a>
                            <ul className="navbar-nav nav-block">
                                <li className="nav-item">
                                    <NavLink to="/shop" className={handleActiveStyle}>
                                        Shop
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/faq" className={handleActiveStyle}>
                                        FAQs
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/contact" className={handleActiveStyle}>
                                        Contact
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </Fragment>
    )
}